import { computed } from 'vue'
import { Widget } from 'Widget/class/Widget'

import { vm } from '@/main'
import { AdFormat } from 'lib/types/ad-format'

import { useTime } from 'components/creatives/utils/use-time'
import { i18n } from '@/i18n'

export function useAdvertState (widget: Widget) {
  const creativesManager = widget.creativesManager
  const { time } = useTime(creativesManager)
  const platform = widget.data.value.platform

  const streamerName = computed(() => {
    let name = widget.data.value[platform]?.displayname ||
      widget.data.value.streamer?.name ||
      vm.$route.query.streamer ||
      'DemoStreamer'

    if (vm.$store.getters['auth/role'] === 'streamer') {
      name = vm.$store.getters['auth/name']
    }

    return name
  })

  const mainText = computed(() => {
    return `${i18n.tc('views.widget.adForSupport')} ${streamerName.value}`
  })

  const introTimer = computed(() => {
    return `${i18n.tc('views.widget.willStartIn')} ${time.value}`
  })

  const playingTimer = computed(() => {
    const one = widget.creativesManager.currentCreativeIndex.value + 1
    const of = i18n.tc('views.widget.of')
    // @ts-expect-error
    const length = creativesManager.adSet.value.filter(ad => ![AdFormat.YANDEX_TEXT, AdFormat.CHATBOT_TEXT].includes(ad.adSet.format)).length

    if (!time.value) {
      return `• ${one} ${of} ${length}`
    }
    return `• ${one} ${of} ${length} • ${time.value}`
  })

  const timerText = computed(() => {
    if (widget.creativesManager.isIntro.value) {
      return introTimer.value
    }
    return playingTimer.value
  })

  const state = computed(() => {
    return `${mainText.value} ${timerText.value}`
  })

  return { state }
}
