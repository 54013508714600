import { ReverseMap } from 'lib/types/base-types'

export enum AdFormat {
  LEADERBOARD = 'leaderboard',
  FULLSCREEN = 'video',
  YANDEX_PF = 'yandex_pf',
  YANDEX_FS = 'yandex_fs',
  YANDEX_TEXT = 'yandex_text',
  PIP = 'pip_video',
  CUSTOM = 'custom',
  INTERACTIVE = 'interactive',
  PREROLL = 'preroll',
  CHATBOT_TEXT = 'chatbot_text',
}

export type AdFormatType = ReverseMap<typeof AdFormat>
